.react-datepicker__navigation {
    height: 15px;
    width: 15px
}
.react-datepicker__header{
    background-color:rgb(33, 123, 167);
    
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    color:#fff
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    color:#fff
}
.react-datepicker__day {
    color:black
}
.react-datepicker__day--selected{
    color:#fff;
    background-color:rgb(33, 123, 167);
}
