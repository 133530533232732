

.messagesInbox
{
    max-height: 620px;
    overflow-y: scroll;
}

.left-box
{
    margin-bottom:42px;

}

.left-box .img
{
    width: 90px;
    min-width: 90px;
    height: 85px;
    background-color: #f0f0f0;
    border: 0;
    box-shadow: 0px 3.89114px 3.89114px rgba(0, 0, 0, 0.25);
    border-radius: 1.94557px;   
    margin-right:11px; 
}

.left-box .textbox
{
    background: #F6F9FE;
    border-radius: 3px;
    padding-left: 26px;
    padding-bottom: 18px;
    padding-right: 40px;
    padding-top: 7px;
}

.left-box .textbox .date
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #C4C4C4;
    margin-bottom: 12px;
   
}

.left-box .textbox .text
{
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 163.44%;
    color: #4F4F4F;
}

.right-box
{
    margin-bottom:42px;

}

.right-box .img
{
    width: 90px;
    min-width: 90px;
    height: 85px;
    background-color: #f0f0f0;
    border: 0;
    box-shadow: 0px 3.89114px 3.89114px rgba(0, 0, 0, 0.25);
    border-radius: 1.94557px;   
    margin-left:11px; 
}

.right-box .textbox
{
    background: #0C78B5;
    border-radius: 3px;
    padding-left: 26px;
    padding-bottom: 18px;
    padding-right: 40px;
    padding-top: 7px;
}

.right-box .textbox .date
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #C4C4C4;
    margin-bottom: 12px;
   
}

.right-box .textbox .text
{
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 163.44%;
    color: #FFFFFF;
}