.logland 
{
    min-height: 1193px;
    background: linear-gradient(180deg, #F6F9FE 0%, rgba(205, 206, 207, 0) 100%);
}

.logland .logleft
{
    width:47%;
}

.logland .logleft .leftAligner
{
    max-width: 675px;
    min-width: 675px;
}

.logland .logleft .leftAligner .verticalTittle
{
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);

    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 59px;
    text-align: justify;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF; 
    left: 260px;
    top: 300px;
}

.logland .logleft .leftAligner .facebutton
{
    margin-left: 115px;
    margin-right:162px;
    padding-top: 83px;
}

.facebook
{
}
.facebook:before {
    content: url('../../Assets/social-facebook-circular-button.svg');
    width: 51px;
    height: 49.81px;
    margin-left: -79px;
    margin-top: -17px;
    position: absolute;
    cursor: pointer
  }

.logland .logleft .leftAligner .facebutton button
{
    background: #2686B0;
    border-radius: 25px;

    width: 100%;
    height: 50px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 0.2em;
    
    color: #FFFFFF;
    

}


.logland .logleft .leftAligner .googlebutton
{
    margin-left: 115px;
    margin-right:162px;
    padding-top: 35px;
}
.google
{
}
.google:before {
    content: url('../../Assets/social-google-plus-circular-button.svg');
    width: 51px;
    height: 49.81px;
    margin-left: -88px;
    margin-top: -17px;
    position: absolute;
    cursor: pointer
  }

.logland .logleft .leftAligner .googlebutton button
{
    background: #2686B0;
    border-radius: 25px;

    width: 100%;
    height: 50px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 0.2em;
    
    color: #FFFFFF;
    

}

.logland .logleft .leftAligner .email
{
    margin-left: 115px;
    margin-right:144.5px;
    padding-top: 70px;
    
}


.logland .logleft .leftAligner .password
{
    margin-left: 115px;
    margin-right:144.5px;
    padding-top: 58px;
    
}

.logland .logleft .leftAligner input
{
    background-color: transparent;
    border:0;
    border-bottom: 1px solid #A8A6A6;

    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.2em;
    
    color: #000000;
    width: 100%;

}

.logland .logleft .leftAligner input::placeholder
{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.2em;
    
    color: #4F4F4F;
}

.logland .logleft .leftAligner .forgottenPassword
{
    font-family: Monda;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 18px;
    
    text-align: right;
    letter-spacing: 0.1em;
    
    color: #1B4973; 
    padding-top:46px;
    margin-right:144.5px;
}

.logland .logleft .leftAligner .myLoginButton
{
    width: 100%;
    padding-top:77px;
    padding-left: 113px;
    padding-right: 143px;

}
.logland .logleft .leftAligner .myLoginButton button
{
    background: #2686B0;
    border-radius: 6px;
    border: 0;
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #FFFFFF;    

    width: 100%;
    height: 65px;    

}

.logland .logleft .leftAligner .aligner
{
    width: 100%;
    padding-left: 5px;
    padding-right: 35px;
    padding-top: 97px;
    font-family: Monda;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.05em;

color: #4F4F4F;
}

.logland .logleft .leftAligner .aligner .leftpos
{
    width: 100%;
}
.logland .logleft .leftAligner .aligner .rightpos
{
    width: 100%;
    text-align: right;
}

.logland .logright
{
    width: 53%;
    background: linear-gradient(0deg, #1D456D 0%, #0C78B5 100%);    
}

.logland .logright .rightAligner .rightTittleUp
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 59px;
    text-align: justify;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25); 
    text-align: center; 
    padding-top: 211px;  
}

.logland .logright .rightAligner .rightTittleDown
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 133.804px;
    line-height: 157px;
    text-align: justify;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 5.35216px 33.451px rgba(0, 0, 0, 0.25), 0px 5.35216px 5.35216px rgba(0, 0, 0, 0.25);
    text-align: center;   
}


@media screen and (max-width: 1200px) {
    .logland .logleft .leftAligner .facebutton 
    {
        margin-left: 209px;
        margin-right: 150px;
    }

    .logland .logleft .leftAligner .facebutton button {

        padding-left: 40px;
    }    
  
    .facebook:before {
        margin-left: -54px;
    }

    .logland .logleft .leftAligner .googlebutton 
    {
        margin-left: 209px;
        margin-right: 150px;
    }

    .logland .logleft .leftAligner .googlebutton button {

        padding-left: 22px;
    }    
  
    .google:before {
        margin-left: -55px;
    }



    .logland .logleft .leftAligner .email {
        margin-left: 208px;
        margin-right: 151.5px;
       
    }

    .logland .logleft .leftAligner .password {
        margin-left: 208px;
        margin-right: 151.5px;
    }  
    
    .logland .logleft .leftAligner .myLoginButton {

        padding-left: 211px;
        padding-right: 152px;
    }

    .logland .logleft .leftAligner .aligner {
        
        padding-left: 102px;
        padding-right: 44px;
    }


    .logland .logright .rightAligner .rightTittleUp
    {
        font-size: 31.5613px;
        line-height: 37px;
    }

    .logland .logright .rightAligner .rightTittleDown
    {
        font-size: 83.5404px;
        line-height: 98px;
    }



}

@media screen and (max-width: 992px)
{

    .logland .logleft .leftAligner {
        max-width: 584px;
        min-width: 584px;
        /* width: 99%; */
    }
    .logland .logleft .leftAligner .verticalTittle {
        display: none;
    }
    .logland .logleft .leftAligner .facebutton {
        margin-left: 229px;
        margin-right: 39px;
    }
    .logland .logleft .leftAligner .facebutton button {

        padding-left: 40px;
    }    
  
    .facebook:before {
        margin-left: -54px;
    }    


    .logland .logleft .leftAligner .googlebutton 
    {
        margin-left: 229px;
        margin-right: 39px;
    }

    .logland .logleft .leftAligner .googlebutton button {

        padding-left: 22px;
    }    
  
    .google:before {
        margin-left: -54px;
    }

    .logland .logleft .leftAligner .email {
        margin-left: 230px;
        margin-right: 40.5px;
    }
    .logland .logleft .leftAligner .password {
        margin-left: 230px;
        margin-right: 40.5px;
    }    

    .logland .logleft .leftAligner .forgottenPassword {

        margin-right: 40.5px;
    }
    .logland .logleft .leftAligner .myLoginButton {
        padding-left: 232px;
        padding-right: 43px;
    }

    .logland .logleft .leftAligner .aligner {
        /* padding-left: 126px; */
        /* padding-right: 0px; */
        padding-left: 0;
        padding-right:0;
        margin-left: 232px;
        margin-right: 43px;
    }

}

@media screen and (max-width: 767px)
{

    .logland .logleft
    {
        width: 100%;
    }    

    .logland .logright
    {
        width: 100%;
    }


    .logland .logright .rightAligner
    {
        height: 123px;
    }

    .logland .logright .rightAligner .rightTittleUp
    {
        padding-top: 79px;
    }

    .logland .logleft .leftAligner .facebutton {

        padding-top: 40px;
    }    

    .logland .logleft .leftAligner {
        max-width: 375px;
        min-width: 375px;
        /* width: 99%; */
    }

    .logland .logleft .leftAligner .facebutton {
        margin-left: 9%;
        margin-right: 9%;
    }   
    
    .logland .logleft .leftAligner .googlebutton {
        margin-left: 9%;
        margin-right: 9%;
    }      

    .logland .logleft .leftAligner .email {
        margin-left: 9%;
        margin-right: 9%;
    }
    .logland .logleft .leftAligner .password {
        margin-left: 9%;
        margin-right: 9%;
    }    

    .logland .logleft .leftAligner .forgottenPassword {

        margin-right: 9%;
    }  
    
    .logland .logleft .leftAligner .myLoginButton {
        padding-left: 9%;
        padding-right: 9%;
    }

    .logland .logleft .leftAligner .aligner {

        margin-left: 9%;
        margin-right: 9%;
    }    

}