.innerContainer-rating
{
    padding-left:43px;
    padding-right:43px;
    padding-top:98px;
}

.innerContainer-rating .innerAligner
{
    width: 100%;
}

.card-rating
{
    width: 100%;
    min-width: 100%;
    height: 338px;
    background: #FFFFFF;
    box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    margin-left:0px;
    margin-right:0px;
    margin-bottom: 39px;
}

.card-rating .images
{
    width: 365px;
    min-width: 365px;
    height: 338px;
    border-radius: 6px;
    background-color: #C4C4C4;  
    border: 0;
    object-fit: cover;
    object-position: center;
}

.card-rating .leftholder
{
    padding-left: 41px;
    padding-top: 45px;
    padding-bottom: 30px;
    padding-right:37px;

}

.card-rating .names
{

    width: 100;

    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: justify;
    letter-spacing: 0.2em;
    text-transform: capitalize;
    
    color: #4F4F4F;

    max-height: 25px;
    text-overflow: ellipsis;
    margin-bottom: 43px;

}

.card-rating .ratedelement
{
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    text-align: justify;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #4F4F4F;
    margin-bottom: 12px;

}
.card-rating .tittlepadding
{
    width: 171px;
}
.card-rating .descriptionContainer
{
    padding-top: 12px;
}
.card-rating .description
{
    
    max-height: 79px;
    height: 79px;

    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    
    letter-spacing: 0.1em;
    text-transform: capitalize;
    color: #4F4F4F;

}

.card-rating .description .btextarea
{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
         box-sizing: border-box;
         height: 80px;
         resize: none;
         border: 1px solid #4F4F4F;
         
         border-radius: 6px;         
}

.card-rating .buttonContainer
{
   
    padding-left:40px;
    padding-right:40px;
    width:100%;
    top:-65px
}
.card-rating .buttonContainer .decor
{
    width: 100%;
    border-bottom: solid 1px #FFFFFF;
    position: relative;
    bottom: 17px;
    padding-left:3px;
    padding-right:3px;
    
}

.card-rating .buttonContainer .cardbutton
{

    width: 100%;
    height: 50px;


    background: #0D74B0;
    border-radius: 6px;
    border: 0;
    
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF;
}
.card-rating .buttonContainer .cardbutton:hover
{
    opacity: 0.8;    
}


.card-rating .buttonContainer .cardbutton-rated
{
    width: 100%;
    height: 50px;
    background: #B3A067;
    border-radius: 6px;
    border: 0;
    
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF;    
    cursor: default;
}

.card-rating .stars
{
    margin-right:5px;
    min-width:18px;
    max-width:18px;
}

.card-rating .stars-active
{
    margin-right:5px;
    cursor: pointer;
    min-width:18px;
    max-width:18px;    
}


@media screen and (max-width: 1440px) {

    .innerContainer-rating
    {
        width:100%
    }
    
    .innerContainer-rating .innerAligner
    {
        width:  786px;
    }    

}

@media screen and (max-width: 1045px) {

    .innerContainer-rating
    {
        width:100%
    }
    
    .innerContainer-rating .innerAligner
    {
        width: 394px;
    }  
    
    .card-rating{
        flex-wrap: wrap!important;
        height:unset;
    }
    .card-rating .leftholder {
        order: 1;
    }
    .card-rating .rightholder {
        order: 0;
        width:100%
    }   
    .card-rating .images {
        width:100%;
        min-width: unset;
    } 

}

@media screen and (max-width: 768px) {

    .innerContainer-rating
    {
        padding-left:0;
        padding-right:0;
    }

}

@media screen and (max-width: 576px) {
    
    .innerContainer-rating .innerAligner
    {
        width: 100%;
        padding-left:10px;
        padding-right:10px;
    }     
    .card-rating
    {
        width: 100%;
        min-width: 0;
        
    }
    .card-rating .description {
        max-width: 100%;
    }


}