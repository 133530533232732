.expert
{
    margin-top: 80px;
    margin-left:0px;
    max-width: 1440px;
}
.expert .askExpertImg
{
    
    width: 399px;
    height: 528px;
    min-width: 399px;
    min-height: 528px;
    max-width: 399px;
    max-height: 528px;
    border-radius: 12px;
    border :0;
    background-image: url("../../../Assets/decorator3.jpeg");
    background-color: #C4C4C4;
    background-size:cover;
    object-fit: cover;
    background-repeat: no-repeat;
    box-shadow: 0px 5px 60px rgba(0, 0, 0, 0.6);    
    margin-right: 160px;  
}

.expert .askExpertTittle
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 69px;
    text-align: center;
    letter-spacing: 0.2em;
    color: #1D456D;

    
    padding-bottom: 28px;
    margin-top:3px;
}

.expert .askDescription
{
    width: 421px;
    height: 128px;
    min-width: 421px;
    min-height: 128px;
    max-width: 421px;
    max-height: 128px;

    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.1em;
/*     text-transform: capitalize; */
    color: #3B3B3A;
    margin-bottom: 36px;        
}

.expert .askButton
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF;  

    width: 394px;
    height: 55px;
   
    background: #2686B0;
    border-radius: 6px;
    margin-bottom: 50px;    
}

@media screen and (max-width: 1200px) {

    .expert .askExpertImg
    {
        margin-right: 0px; 
    }


}


@media screen and (max-width: 992px) {
    .expert .askButton
    {
        margin-left: -15px;
    }
    .expert .askDescription {
        margin-left: 18px;
    }
}