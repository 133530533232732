.innerContainer
{
    padding-left:10px;
    padding-right:4px;
    padding-top:98px;
}

.innerContainer .innerAligner
{
    width: 100% !important;
}
.offersdetail
{
  padding-left:77px;
  padding-right:60px;
}

.offersdetail .offersadstittle
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 49px;

    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;

    color: #4F4F4F;
}
.offersdetail .tittlemodder
{


    font-size: 15px;
    line-height: 53px;

}


.offersdetail .offersadssection
{
    background: #FFFFFF;
    box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    margin-bottom: 46px;
}

.offersdetail .offersadssection .vdecor
{
    border: 1px solid #4F4F4F;
    margin-top: 50px;
    margin-bottom: 39px;
    order:2;
}
.offersdetail .offersadssection .offersads-left
{
    max-width: 350px;
    width: 100%;
    padding-left: 49px;
    padding-top: 32px;
    padding-right: 25px;
    padding-bottom: 32px;
    order: 1;
    
}
.offersdetail .offersadssection .offersads-left .adsleft-subtittle
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: left;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #4F4F4F;
}
.offersdetail .offersadssection .offersads-left .offpaddingsmall
{
    margin-bottom: 18px;
}
.offersdetail .offersadssection .offersads-left .adsleft-text
{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: capitalize;
    text-align: justify;
    color: #4F4F4F;
}


.offersdetail .offersadssection .offersads-middle
{
    max-width: 350px;
    width: 100%;
    padding-left: 25px;
    padding-top: 43px;
    padding-right: 0px;
    padding-bottom: 0px;  
    order: 3;
}

.offersdetail .offersadssection .offersads-middle .adsright-subtittle
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #1E466D;
}

.offersdetail .offersadssection .offersads-middle .adsright-text
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 29px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #4F4F4F;    
}

.offersdetail .offersadssection .offersads-middle .offpaddingsmall
{
    margin-bottom: 18px;
}

.offersdetail .offersadssection .offersads-right
{
    order:4;
}

.offersdetail .offersadssection .offersads-right .offersads-img
{
    width: 347px;
    height: 293px;
    object-fit: cover;
    object-position: center;
}

.offersdetail .offersads-hdekor
{
    border-top: 0.5px solid #4F4F4F;
    margin-top:22px;
    margin-bottom:22px;
    min-height: 1px;
}
.offersdetail .tittlemargin
{
    margin-top: 50px;
    margin-bottom: 30px;
    
}
.offersdetail .tittlemarginb
{
    margin-top: 20px;
    margin-bottom: 80px;
    
}
.offersdetail .offersadsdescription
{
    font-family: Open Sans;
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 20px;
letter-spacing: 0.1em;
text-transform: capitalize;
color: #4F4F4F;
margin-bottom: 20px;
}

.offercolor
{
    color: #1C466F;
}
.offersdetail .offersads-offerssection .offersads-elements .offeradselementcontainer {

    max-width: 500px;
    min-width: 500px;

    font-family: Maven Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 15px;
    line-height: 53px;


    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #4F4F4F;

}

.offersdetail .offersads-alldecor
{
    margin-top: 68px;
    
}

.offersdetail .offersads-alldecor .sizedekor
{
    width: 350px;
}

.offersdetail .offersads-allprice .offerads-allpricecontainer
{
    width:350px;
}

.offersdetail .offersads-allprice .offerads-allpricecontainer .alltext
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 15px;
    line-height: 53px;

    letter-spacing: 0.3em;
    text-transform: uppercase;
    
    color: #4F4F4F;
}
.offersdetail .offersads-allprice .offerads-allpricecontainer .allprice
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 25px;
    line-height: 88px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    
    color: #1E466D;
}

.offersdetail .offersads-endbuttons
{
    margin-bottom:100px;
    margin-top:50px;
}

.offersdetail .offersads-endbuttons .declinebtn
{

    
    width: 339px;
    
    height: 65px;
    background: #C4C4C4;
    border-radius: 6px;
    border: 0;
 
    font-family: Maven Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    
    color: #FFFFFF;
    margin-right:24px;

}
.offersdetail .offersads-endbuttons .declinebtn:hover
{
    opacity: 0.8;    
}

.offersdetail .offersads-endbuttons .acceptbtn
{

    width: 406px;
    
    height: 65px;

    
    background: #217BA7;
    border-radius: 6px;
    border: 0;

    
    font-family: Maven Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 23px;

    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    
    color: #FFFFFF;
    
}
.offersdetail .offersads-endbuttons .acceptbtn:hover
{
    opacity: 0.8;    
}


.offersdetail .demotivator
{
    padding-left:80px;
    padding-right:90px;
}

.offeraccepted
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 88px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    
    color: #1E466D;
}



@media screen and (max-width: 1440px) {

    .innerContainer
    {
        width:100%
    }
    
    .innerContainer .innerAligner
    {
        width:  100%;
    } 

    .offersdetail .offersadssection .offersads-left
    {
        order:2;
        max-width: 57%;
        width:100%;
    }    
    .offersdetail .offersadssection .offersads-right
    {
        order:1;
    }
    .offersdetail .offersadssection .offersads-middle
    {
        order:4;
        max-width: 100%;
        text-align: center;
        padding-left: 0px;
        margin-bottom: 30px;
        padding-top: 0px; 

    }    
    .offersdetail .offersadssection .vdecor
    {
        order:3;
        border-right :none;
        border-left:none;
        width: 100%;
        border-bottom: solid 1px;
        margin-left:30px;
        margin-right:30px;
    }   
    
    
    .offersdetail .offersads-endbuttons .acceptbtn
    {
        width: 375px;
    }

    .offersdetail .offersads-endbuttons .declinebtn
    {
        width: 240px;
    }

}


@media screen and (max-width: 1200px) {


    .offersdetail .offersadssection .offersads-left
    {
        order:2;
        max-width: 100%;
        width:100%;
    }    
    .offersdetail .offersadssection .offersads-right
    {
        order:1;
        width:100%;
    }
    .offersdetail .offersadssection .offersads-right .offersads-img {
        width: 100%;
    }
    .offersdetail .offersadssection .offersads-middle
    {
        order:4;
        max-width: 100%;
        text-align: center;
        margin-bottom: 30px;
        padding-top:30px;
        

    }    
    .offersdetail .offersadssection .vdecor
    {
        order:3;
   
        margin-top: 15px;
        margin-bottom: 0px;
    }     
    
    
    .offersdetail .offersads-offerssection .offersads-elements .offeradselementcontainer {
        max-width: 400px;
        min-width: 400px;
        }

        .offersdetail .offersads-endbuttons .acceptbtn
        {
            width: 210px;
            font-size: 16px;
        }
    
        .offersdetail .offersads-endbuttons .declinebtn
        {
            width: 199px;
            font-size: 16px;
        }


}

@media screen and (max-width: 992px) {

    .offersdetail .demotivator {
        padding-left: 0px;
        padding-right:0px;
    }
    .offersdetail .offersads-offerssection .offersads-elements .offeradselementcontainer {
    max-width: 400px;
    min-width: 400px;
    }


    .offersdetail .offersads-endbuttons .acceptbtn
    {
        width: 200px;
        font-size: 16px;
    }

    .offersdetail .offersads-endbuttons .declinebtn
    {
        width: 154px;
        font-size: 16px;
               
    }


}
@media screen and (max-width: 576px) {

        .offersdetail {
            padding-left: 10px;
            padding-right: 10px;
        }

        .offersdetail .offersads-offerssection .offersads-elements .offeradselementcontainer {
            max-width: 300px;
            min-width: 300px;
            }   
            
            .offersdetail .offersads-endbuttons .acceptbtn
            {
                width: 100%;
                font-size: 16px;
            }
        
            .offersdetail .offersads-endbuttons .declinebtn
            {
                width: 100%;
                font-size: 16px;
                margin-right: 0px;
                margin-bottom: 30px;                
            }

}