.innerContainer
{
    padding-left:10px;
    padding-right:4px;
    padding-top:98px;
}

.innerContainer .innerAligner
{
    width: 768px;
}
.newads
{
  padding-left:77px;
  padding-right:60px;
}
.newads .headerTittle
{
  font-family: Maven Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 68.0669px;
  line-height: 80px;
  

  letter-spacing: 0.2em;
  text-transform: uppercase;

  color: #4F4F4F;

  margin-bottom: 75px;
  text-align: left;
  width:50%;
}

.newads .subtittle
{
  font-family: Maven Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 49px;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  
  color: #4F4F4F;
  margin-bottom:10px;
  
}
.newads .hdecor
{
  border: 0.5px solid #4F4F4F;
  width: 100%;
  margin-bottom:30px;
}

.newads .selectLeftsa
{
  padding-left:50px;
}
.newads .selectRightsa
{
  padding-right:50px;
}

.newads .selectLeft
{
  padding-left:50px;
}
.newads .selectRight
{
  padding-right:50px;
}

.newads .subsub
{
  font-family: Monda;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 49px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  

  color: #4F4F4F;
  margin-top:30px;
  
  margin-bottom: 75px;
}

.newads .marginer
{
  margin-bottom:70px;
}

.newads .marginerb
{
  margin-bottom:40px;
  margin-top:40px;
}
.newads .marginerc
{
  margin-bottom:50px;
}

.newads .marginerd
{
  margin-bottom:70px;
  margin-top:40px;
}
.newads .benceImage
{
    width: 293px;
    min-width: 293px;
    height: 186px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    margin-top: 20px;
    margin-bottom: 50px;
}

.newads input
{
    width: 100%;
    height: 50px;

    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #000000;   
    border: 1px solid #4F4F4F;
    box-sizing: border-box;
    border-radius: 6px;
    padding-left: 15px;
}

.newads .btextarea
{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
         box-sizing: border-box;
         height: 280px;
         resize: none;
         border: 1px solid #4F4F4F;
         
         border-radius: 6px; 
         margin-top:10px;        
}

.newads .sendbutton
{
    height: 65px;
    max-width: 340px;
    width: 100%;
    background: #0D74B0;
    border-radius: 6px;
    border: 0;
    
    font-family: Maven Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    
    color: #FFFFFF;
}

.newads .containerchecka {
    display: block;
    position: relative;
    padding-left: 45px;
    margin-bottom: 35px;
    cursor: pointer;
    
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 29px;
    /* or 29px */
    
    letter-spacing: 0.3em;
    text-transform: uppercase;
    
    color: #4F4F4F;
    


  }
  
  /* Hide the browser's default checkbox */
  .newads .containerchecka input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmarka {
    position: absolute;
    top: 4px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 0.5px solid #4F4F4F;
    border-radius: 4px;
    
  }
  
  /* On mouse-over, add a grey background color */
  .newads .containerchecka:hover input ~ .checkmarka {
    background-color: #eee;
  }
  
  /* When the checkbox is checked, add a blue background */
  .newads .containerchecka input:checked ~ .checkmarka {
    background-color: #217BA7;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmarka:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .newads .containerchecka input:checked ~ .checkmarka:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .newads .containerchecka .checkmarka:after {
    
  }

  @media screen and (max-width: 1440px) {
    .innerContainer .innerAligner
    {
        width: 768px;
    }
  }

  @media screen and (max-width: 1200) {
    .innerContainer .innerAligner
    {
        width: 768px;
    }
  }

  @media screen and (max-width: 992px) {

    .innerContainer .innerAligner
    {
        width: 100%;
    }

    .newads .selectRightsa {
      
      padding-right: 0px;
      width: 100% !important;
    }
    .newads .selectLeftsa
    {
      display: none;
    }
    .newads .selectRight{

      width: 100% !important;

    }
    .newads .selectLeft{
      padding-left: 0px;
      width: 100% !important;
    }    


  }

  @media screen and (max-width: 768px) {

    .newads .headerTittle
    {
      font-size: 43.3631px;
      width: 100%;
      text-align: center;

    }

    .newads .subtittle
    {
      text-align: center;    
      
    }    

  }

  @media screen and (max-width: 576px) {
    .newads
    {
      padding-left:10px;
      padding-right:10px;
    }  

    .newads .subtittle
    {
      line-height: 28px;  
      
    }  
    
  }