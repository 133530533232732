.header
{
    height:115px;
    min-height: 115px;
    max-height: 115px;
    
    margin-right: 0px;
    margin-left: 0px;
    color: aquamarine;
    background: rgba(79, 79, 79, 0.4);
    border-bottom: 1px solid #FFFFFF;
    box-sizing: border-box;
}

.headerMaxSize
{
    max-width: 1440px;

}

.menulink
{
    min-width: 148px;
    text-align: center;
    
}
.menuLogo
{
   
    padding-right: 10px;
    padding-left:120px
}
.spacerBetweenLogoAndFirstMenuPoint
{
    min-width: 408px;
    width:408px;
    max-width: 408px;
    display: none;
}

.spacerBetweenSecondAndFirstMenuPoint
{
    min-width: 68px;
    width:68px;
    max-width: 68px;
}

.spacerBetweenSecondAndThirstMenuPoint
{
    min-width: 115px;
    width:115px;
    max-width: 115px;
}

.spacerBetweenLastAndScreen
{
    min-width: 120px;
    width:120px;
    max-width: 120px;    
}

.dinamicSpacer{
    
   
    display: flex;
    flex: 1;
}

.menuContainer
{
    
    display:flex !important;
    margin-right: 120px;
}
.menuShort{
    display:none;
    margin-right:30px;
}

@media screen and (max-width: 922px) {
   .menuContainer
   {
    display: none !important;   
   }
   .menuShort{
       display: block;
   }
   .menuLogo{
        padding-left: 8%; 
   }
  }

.menufont{
    font-family: 'Maven Pro', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    
    color: #FFFFFF;    
}

.loginButton{
    background-color: rgba(196, 196, 196, 0.6);
    border-radius: 2px;
    border: 0;
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
    width: 109px;
    height: 44px;
    
    

}

.loginButton:hover {
    background-color: rgba(196, 196, 196, 0.8);
}






.bgimage
{
   
    z-index: -10;
}
.elasticImage
{
    height:699px;
    display: flex;
    flex: 1;
    background-image: url('../../Assets/header_left_background.png');
    background-repeat:no-repeat;
    background-size:100% 100%;
}
.fixImageRight
{
    height:699px;
    width:992px;
    background-image: url('../../Assets/header_right_background.png');
    background-repeat:no-repeat;
    background-size:100% 100%;
    background-size:cover;
    background-position: center;
    
}





.searchboxes
{
    min-height: 584px;
    max-height: 584px;
    height: 584px;
    max-width: 1440px;
    

    
}

.searchAligner
{
    margin-bottom: -20px;
    
}

.searchBigTittle{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 85px;
    line-height: 100px;
    text-align: right;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF;
}
.searchMediumTittle{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 50.5263px;
    line-height: 59px;
    text-align: right;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF;
    padding-bottom: 35px;
}
.searchLittleTittle{

    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 34px;
    letter-spacing: 0.2em;
    color: #FFFFFF;    
}

.searchInput{
    width: 400px;
    height: 50px;
    background: #FFFFFF;
    border-radius: 2px;
    border:0; 
    
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #4F4F4F;
    padding-left: 17px;
    opacity: 1; /* Firefox */    

}

.searchInput::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #8F8F8F;
    
    opacity: 1; /* Firefox */
  }
  
.searchInput:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    
    color: #8F8F8F;
  }
  
.searchInput::-ms-input-placeholder { /* Microsoft Edge */
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    
    color: #8F8F8F;
  }

.searchInputFirstPadder{
      padding-bottom: 25px;
}

.searchInputSecondPadder{
    padding-bottom: 30px;
}
.searchInputThirdPadder{
    padding-bottom: 31px;
}



.searchButton{

    
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;

    background-color: rgba(38,134,176,1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    border: 0;
    width: 400px;
    height: 71px;
    

}

.searchButton:hover {
    background-color: rgba(38,134,176,0.8);
}

.searchRightAligner
{
    margin-bottom: -43px;
    padding-left: 100px;
}


@media screen and (max-width: 1200px) {
    .searchAligner{
        padding-left: 0px;
        padding-right: 0px;
    }
    .searchRightAligner{
        padding-left: 0px;
        padding-right: 0px;
    }    
    .searchBigTittle
    {
        text-align: center;
    }
    .searchMediumTittle
    {
        padding-bottom: 20px;
        text-align: center;
    }
    .searchLittleTittle{
        font-size: 20px;
        line-height: 25px;
        text-align: center;
    }
    .searchRightAligner{
        margin-bottom: 0px;
        padding-right: 0px;
        padding-left: 0px;
        padding-top: 20px;

    }
    .searchInputFirstPadder
    {
        padding-bottom: 20px;
    }
    .searchInputSecondPadder
    {
        padding-bottom: 25px;
    }
    .searchInputThirdPadder{
        padding-bottom: 26px;
    }



   }
