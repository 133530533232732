.register
{
    background: linear-gradient(180deg, #F6F9FE 0%, rgba(205, 206, 207, 0) 100%);
}
.registerSelectorDecor
{
    max-width: 620px;
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 83.6688px;
    line-height: 98px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;

    color: #FFFFFF;

    opacity: 0.8;
    margin-top: 60px;
}

.aligner
{
    max-width: 460px;
}

.aligner .tittle
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 27.5947px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    
    color: #2686B0;
    margin-bottom: 30px;
    
}

.aligner .description
{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    /* or 182% */
    
    text-align: justify;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    
    color: #4F4F4F;

    margin-bottom: 60px;
    
}


.aligner .calign
{
    text-align: center;
    margin-bottom: 120px;
}

.aligner button
{

    width: 290px;
    height: 60px;

    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #FFFFFF;
    
    border:0;
    background: #2686B0;
    border-radius: 6px;

}

.raligner
{
    margin-left:55px;
    padding-right: 30px;
}
.laligner
{
    
    margin-right:55px;
    padding-left:30px;
}


.registerSelectorRow {
    padding-top: 310px;
}


.registerSelectorDecorLine
{
    width: 1px;
    height: 352px;
    background-color: #4F4F4F;
    margin-top: 305px;

}

.regmobiletittleblock
{
    width: 375px;
    margin-top: 110px;
}
.regmobiletittleblock div
{
    padding-bottom:6px;
}
.regmobiletittleblock .askone
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 27.5947px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #2686B0;
}
.regmobiletittleblock .asktwo
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;    
    color: #4F4F4F;  
}

.regmobiletittleblock .askdivider
{
    width: 200px;
    height: 1px;
    margin-left: 87.5px;
    padding-top: 30px;
    border-bottom: 1px solid #4F4F4F;
}



@media screen and (max-width: 991px) {

    .registerSelectorDecorLine
    {
        display:none;
    }
    .raligner
    {
        margin-left:0px;
        padding-right: 0px;
    }
    .laligner
    {
        
        margin-right:0px;
        padding-left: 0px;
    }    
    .registerSelectorRow {
        padding-top: 305px;
    }

    .registerSelectorDecor
{
    max-width: 375px;
    font-size: 46.0714px;
    line-height: 54px;

    margin-top: 5px;
}
    

}