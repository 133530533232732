.decorFirstImage
{
    padding-top: 22px;
    padding-left: 25px;
}

.decorTittle
{
    font-family: Monda;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 182.52%;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #4F4F4F;

    top:90px;
    left:152px;

}

.workTittle{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 65.4545px;
    line-height: 72px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #2686B0;
    padding-left: 39px;
    padding-top: 123px;

}
.workTittle button {
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #FFFFFF;

    background: #2686B0;
    border-radius: 6px; 
    border :0;   
    width: 415px;
    height: 72px;  

}

@media screen and (max-width: 1200px) {

    .decorFirstImage{
        display:none;
    }
    .decorTittle{
        left: 0px;
        text-align: center;
    }
    .workTittle{

        padding-left:25px;
    }

}

