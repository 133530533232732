.benceImage
{
    width: 210px;
    min-width: 210px;
    height: 210px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    margin-top: -8px;
}


.containercheck {
    display: block;
    position: relative;
    padding-left: 45px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .containercheck input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border: 0.5px solid #4F4F4F;
    border-radius: 4px;
    
  }
  
  /* On mouse-over, add a grey background color */
  .containercheck:hover input ~ .checkmark {
    background-color: #eee;
  }
  
  /* When the checkbox is checked, add a blue background */
  .containercheck input:checked ~ .checkmark {
/*   background-color: #217BA7;  */
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .containercheck input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .containercheck .checkmark:after {
    
  }



.registeruser
{
    background: #F6F9FE;
}
.registerUserRow
{
    padding-top:115px;
}
.registerUserDecor
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 90.8333px;
    line-height: 107px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-top: 10px;
}

.registerUserBlock
{
    background: #FFFFFF;
    box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    margin-left: 120px;
    padding-left: 50px;
    margin-right: 120px;
    padding-right: 50px;
    padding-top: 33px;
    padding-bottom: 50px;
    margin-bottom: 92px;
    max-width:1185px;
    min-width:1185px;
}

.registerFormTittle
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #4F4F4F;
    padding-left: 9px;
}

.hdivider
{
    width:100%;
    height:1px;
    border-bottom: 0.5px solid #4F4F4F;
    padding-left: 5px;
    padding-bottom: 20px;
    margin-bottom: 55px;
   
}


.hdividerbottom
{
    width:100%;
    height:1px;
    border-bottom: 0.5px solid #4F4F4F;
    padding-left: 5px;
    padding-bottom: 20px;
    margin-bottom: 30px;
   
}

.rightcontainer label
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #4F4F4F;   
    padding-top: 15px; 
}

.rightcontainer input
{
    width: 367px;
    height: 40px;

    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #000000;   
    border: 0.5px solid #989898; 
}




.rightcontainer
{
    margin-top: -28px;
}
.rightblock
{
    margin-left: 36px;
}
.rightblockslide
{
    margin-left: 44px;
}

.paddingModifier
{
    padding-top: 40px; 
}

.downside label
{
    padding-top: 18px;
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #4F4F4F;   
     
}
.downside{
    margin-left: -8px;
}
.downside input
{
    width: 621px;
    height: 40px;

    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #000000;   
    border: 0.5px solid #989898;   
}

.containercheck
{
    padding-top: 0px !important;
    width: 300px;
    margin-top: 50px;
    margin-left: 4px;    
}

.form-checks
{
    margin-left: -10px;
}

.form-checks label{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #4F4F4F;

}

.backButton
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF;
    width: 166px;
    height: 64px;
    background-color: #C4C4C4;
    border-radius: 6px;
    border:0;    
    margin-left: 125px;
    margin-top: 0px;
}
.backButton:hover
{
    background-color: rgba(196, 196, 196, 0.8);
}


.registerButton
{
    width: 365px;
    height: 64px;
    background: #217BA7;
    border-radius: 6px;
    border: 0;    
    font-family: Maven Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #FFFFFF;   
    margin-top: 0px; 
    margin-left: 70px;
}

.registerButton:hover
{
    opacity: 0.8;
}


.errorPadding{
    padding-top:10px;
}
.hdivideretop
{
    width:100%;
    height:1px;
    border-bottom: 0.5px solid #4F4F4F;
    padding-left: 5px;
    padding-bottom: 20px;
    margin-bottom: 30px;
}
.hdividerebottom
{
    width:100%;
    height:1px;
    border-bottom: 0.5px solid #4F4F4F;
    padding-left: 5px;
    padding-bottom: 20px;
    margin-bottom: 30px;
}
.errorText
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: right;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #E30606;
}

@media screen and (max-width: 1200px) 
{
    .registerUserBlock
    {
        max-width:976px;
        min-width:976px;       
    }
    .rightblock
    {
        margin-left: 0px;
    }
    .rightblockslide
    {
        margin-left: 0px;
    }
    .rightcontainer{
        margin-top: -45px;
        width: 70%;
        margin-left: 40px;
    }
    .rightcontainer input
    {
        width: 587px;
    }
    .backButton{
        margin-left:15px;
    }
}

@media screen and (max-width: 992px)
{
    .registerUserBlock
    {
        max-width:704px;
        min-width:704px;       
    } 

    .registerUserDecor
    {
        font-size:70px;
    }

    .rightcontainer {
        margin-top: 20px;
        width: 100%;
        margin-left: 0px;
    }
    .rightcontainer input {
        width: 550px;
    }
    .downside {
        
        margin-left: 0px;
    }
    .downside input {
        width: 550px;
    }
    .form-checks {
        margin-left: -4px;    
    }
    .registerButton {
        width: 300px;
        
        margin-left: 70px;
    }
    .backButton {
       
        
        margin-left: 0px;
    }    

} 

@media screen and (max-width: 768px)
{
    .registerUserBlock
    {
        max-width:560px;
        min-width:560px;    

        margin-left: 60px;
        padding-left: 25px;
        margin-right: 60px;
        padding-right: 25px;


    }    

    .registeruser {
        background: #ffffff; 
    }

    .registerUserDecor
    {
        font-family: Maven Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 35px;
        text-align: center;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        color: #A9B7BC;  
        margin-top: 18px;
    }
    .registerUserRow {
        padding-top: 65px;
    }    

    .registerFormTittle {

        padding-left: 0px;
        text-align: center;
    }  
    .hdivider {

        margin-bottom: 40px;
    }  

    .rightblock {
        margin-left: 0px;
        padding-left: 0;
        padding-right: 0;
    }
    .rightblockslide {
        margin-left: 0px;
        padding-left: 0;
        padding-right: 0;
    }
    .rightcontainer input {
        width: 100%;
    } 
    .paddingModifier {
        padding-top: 55px;
    }
    .hdividerbottom {

        margin-bottom: 47px;  
    }
    
    .downside .col
    {
        padding-left: 0px;
        padding-right: 0px;
    }
    .downside input {
        width: 100%;
    }
    .form-checks {
        padding-left: 0px;
        padding-right: 0px;
    }
    .backButton {
       
        width: 100%;
        margin-left: 0px;
    }
    .registerButton {
        width: 100%;
        margin-left: 0px;
        margin-top: 30px;
    }
    .col-md-6 {
        padding-left:0px;
        padding-right:0px;
    }
    .errorText{
        text-align: center;
    }
    .buttons
    {
        width:100%;
    }     
}

@media screen and (max-width: 576px)
{
    .registerUserRow {
       
        padding-left: 20px;
    
        padding-right: 20px;        
    }
    .registerUserBlock
    {
        max-width:100%;
        min-width:100%;    

        margin-left: 20px;
        padding-left: 20px;
        margin-right: 20px;
        padding-right: 20px;


    }  
    .buttons
    {
        width:100%;
    } 
}