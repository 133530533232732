.regland{
    /* max-width: 1440px; */
    min-height: 1193px;
}

.regland .regleft
{
    background: linear-gradient(0deg, #1D456D 0%, #0C78B5 100%);
    min-width: 766px; 
    width: 50%;
    min-height: 1193px;
    
}
.regland .regleft .regAlign
{
    max-width: 585px;
    margin-left: auto;
   
}


.regland .regleft .contentTittle
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 98.4981px;
    line-height: 108px;
    text-align: justify;
    letter-spacing: 0.17em;
    text-transform: uppercase;
    color: #FFFFFF;
    transform: rotate(0deg);
    padding-top: 97px;
    /* padding-left: 150px; */
}
.regland .regleft .contentTittle .letter
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 98.4981px;
    line-height: 108px;
    text-align: justify;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #FFFFFF;
    transform: rotate(0deg);
}

.regland .regleft .content {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12.976px;
    line-height: 18px;
    text-align: justify;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: #FFFFFF;
/*     padding-left: 150px;
    padding-right: 130px; */
    padding-top: 50px;
    padding-bottom: 65px;
    max-width: 410px;

}

.regland .regleft button
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #FFFFFF;

    width: 400px;
    height: 65px;
    background: #2686B0;
    border-radius: 6px;
    border: 0;    
    /* margin-left: 150px; */
}
.regland .regright
{
    position: absolute;
    left: 50%;
    
}
.regland .regright .rightImage
{
    width: 984.89px;
    height: 632px;
    background-image: url('../../../Assets/mesterlaptop.png');
   
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: -50;
    margin-left: -325px;
    margin-top: 254px;    
}

.regland .regright .rightLogo
{
    width: 262px;
    height: 262.55px;
    background-image: url('../../../Assets/Subtract.svg');
    background-color: #FFFFFF;
    background-size: cover;
    object-fit: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: -51;
    margin-left: 310px;
    margin-top: -741px;   
}

@media screen and (max-width: 1440px) {
    .regland .regright
    {
        left:45%
    }
}

@media screen and (max-width: 1200px) {
    .regland .regright
    {
        left:33%
    }
}


@media screen and (max-width: 991px) {

    .regland .regright
    {
       position:relative;
    }    

    .regland .regright .rightImage{
        z-index: 0;
        margin-left: -585px;
    margin-top: -360px;
    }
    .regland .regleft
    {
        min-height: 1350px;
        width: 100%;
        min-width: 100%;
    }
    .regland .regleft .regAlign
    {
        max-width: 410px;
        margin-left: auto;
        margin-right: auto;
    }
    .regland .regleft .content {
        max-width: 100%;
    }

}




@media screen and (max-width: 576px) {

    .regland .regleft
    {
        min-height: 1110px;
    }
    .regland .regleft .contentTittle{
        font-size: 64.9255px;
        line-height: 71px;
        padding-bottom: 30px;

    }

    .regland .regleft .contentTittle .letter
    {
        font-size: 64.9255px;
        line-height: 71px;        
    }

    .regland .regleft .regAlign
    {
        max-width: 300px;
    }
    .regland .regleft button
    {
        width: 300px;
    }

    .regland .regright .rightImage
    {
        width: 657.63px;
        height: 422px;
        left: 187px;
        top: 90px;
    }
    .regland{
        min-height: 1365px;
    }

}