.innerContainer
{
    padding-left:10px;
    padding-right:4px;
    padding-top:98px;
}

.innerContainer .innerAligner
{
    width: 100%;
}

.card
{
    width: 373px;
    min-width: 373px;
    height: 518px;
    background: #FFFFFF;
    box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    margin-left:10px;
    margin-right:10px;
    margin-bottom: 39px;
}

.card .images
{
    width:100%;
    height: 293px;
    border-radius: 6px;
    background-color: #C4C4C4;  
    border: 0;
    object-fit: cover;
}

.card .names
{
    max-height: 28px;

    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    margin-top:19px;
    color: #4F4F4F;
}
.card .descriptionContainer
{
    padding-top: 12px;
}
.card .description
{
    max-width: 320px;
    max-height: 79px;
    height: 79px;

    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: capitalize;
    color: #4F4F4F;

}

.card .buttonContainer
{
    padding-top: 20px;
    padding-left:25px;
    padding-right:25px;
    width:100%;
}

.card .buttonContainer .cardbutton
{

    width: 100%;
    height: 50px;


    background: #0D74B0;
    border-radius: 6px;
    border: 0;
    
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF;
}
.card .buttonContainer .cardbutton:hover
{
    opacity: 0.8;    
}



@media screen and (max-width: 1440px) {

    .innerContainer
    {
        width:100%
    }
    
    .innerContainer .innerAligner
    {
        width:  786px;
    }    

}

@media screen and (max-width: 1045px) {

    .innerContainer
    {
        width:100%
    }
    
    .innerContainer .innerAligner
    {
        width: 394px;
    }    

}

@media screen and (max-width: 768px) {

    .innerContainer
    {
        padding-left:0;
        padding-right:0;
    }

}

@media screen and (max-width: 576px) {
    
    .innerContainer .innerAligner
    {
        width: 100%;
        padding-left:10px;
        padding-right:10px;
    }     
    .card
    {
        width: 100%;
        min-width: 0;
    }
    .card .description {
        max-width: 266px;
    }


}