.rdrMonth{
    width:100%
} 
.rdrDayInPreview{
    background: transparent;
}
.rdrMonthAndYearPickers select{
    color:#FFF;
    background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000") no-repeat;
}
.rdrCalendarWrapper{
    background: linear-gradient(0.1deg, #233657 -4.17%, #0C78B5 -4.17%, #155F92 23.87%, #1E466D 73.51%);
    width:100%
}
.rdrDayDisabled{
    background-color: rgba(150,150,150,0.0);
    cursor: not-allowed!important;
    border-radius:30px
}
.rdrDayNumber span{
    color: #FFF
}
.rdrDayDisabled .rdrDayNumber span {
    color: #000
} 
.rdrDayPassive .rdrDayNumber span {
    color: #000
}
