.innerContainer {
    
}

.messages
{
    background: #FFFFFF;
    box-shadow: 3px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    width:100%;
    margin-right:34px;

   
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 40px;
    padding-bottom: 31px;



}