.outerWorkPadding{
    padding-top: 60px;
}
.outerWorkTittle{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #2686B0; 
    padding-bottom: 22px;   

}

.outerWorkSubTittle{
    font-family: MONDA;
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 55px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #4F4F4F;
    padding-bottom: 22px;   
    

}

.leftOuterWork{

    font-family: Monda;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #3B3B3A;
    width:305px;
    max-width: 305px;
    text-align:left;
 
}

.centerOuterWork{

    font-family: Monda;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #3B3B3A;
    text-align: center;
    width:305px;
    max-width: 305px;
    margin-left: 59px;
    margin-right: 59px;

}

.rightOuterWork{

    font-family: Monda;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #3B3B3A;
    width:305px;
    max-width: 305px;    
    text-align: right;

}

.outerWorkDisplay{
    display: flex;
}

.startDisplay
{
    justify-content: flex-start!important;
}
.centerDisplay
{
    justify-content: center!important;
}
.endDisplay
{
    justify-content: flex-end!important;
}

@media screen and (max-width: 992px) {
    .outerWorkDisplay{
        display: block !important;
    }
    .leftOuterWork{
        text-align: center;
        padding-bottom: 50px;
    }
    .centerOuterWork{
        text-align: center;
        padding-bottom: 50px;
    }
    .rightOuterWork{
        text-align: center;
        padding-bottom: 50px;
    }
    .startDisplay{
        justify-content: center!important;
    }
    .endDisplay{
        justify-content: center!important;
    }

}
