.usersContainer
{
    background-color: #F6F9FE;
    padding-bottom: 98px;

}

.usersContainer .content
{
    max-width: 1440px;
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
    margin-left:auto;
    margin-right:auto;
}

.usersContainer .content .tittle
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 90.8333px;
    line-height: 107px;
    letter-spacing: 0.3em;
    text-transform: uppercase;

    color: #FFFFFF;
    padding-top:15px;
    padding-bottom: 17px;
}

.usersContainer .content .navMenu
{
    height: 122px;
    
    background: linear-gradient(180deg, #233657 0.03%, #0C78B5 0.03%, #155F92 26.95%, #1E466D 74.6%);
    border-radius: 5.2265px 5.2265px 29px 29px ;

    padding-left: 50px;
    padding-right: 60px;
    position: relative;
    z-index:10;
    
}

.usersContainer .content .navMenu .menu .menubtn
{
    background-color: transparent;
    border:0;
    width: 135px;
    height: 122px;

    font-family: Maven Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 12.6237px;
    line-height: 15px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF;    

}
.usersContainer .content .navMenu .menu .menubtn:hover
{
    background-color: #0C78B5;
}



.usersContainer .content .navMenu .menu .menubtn img
{
    margin-top: -5px;
    width:58px;
    height:58px;
}

.usersContainer .content .navMenu .menu .menubtn div
{
    margin-top: 13.5px;
}
    


.usersContainer .content .navMenu .menu .activatedmenubtn
{
    
    border: 0;
    width: 135px;
    height: 142px;
    font-family: Maven Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 12.6237px;
    line-height: 15px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #FFFFFF;

    background: #0C78B5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 8.01681px 8.01681px;
    margin-top: -7px;
    margin-bottom: 0px;
   
}

.usersContainer .content .navMenu .menu .activatedmenubtn img
{
    margin-top: -5px;
    
}

.usersContainer .content .navMenu .menu .activatedmenubtn div
{
    margin-top: 13.5px;
}


.usersContainer .content .innerContent
{
    position: relative;
    background: #FFFFFF;
    box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    margin-top: -30px;
    padding-top:30px;
    
    min-height: 600px;

}

@media screen and (max-width: 1199px) {


    .usersContainer .content .navMenu .menu .menubtn
    {

        width: 108px;
        height: 108px;
        font-size: 11px;
        border-radius: 4px;
        padding-left:0 !important;
        padding-right:0 !important;
        
    }   
    .usersContainer .content .navMenu
    {
        padding-left: 7px;
        padding-right: 10px;
    }  
    .usersContainer .content .navMenu .menu .activatedmenubtn
    {
        width: 108px;
        height: 108px;
        font-size: 11px;
        border-radius: 4px;
        padding-left:0 !important;
        padding-right:0 !important;
        margin-top:0;
        
    }
    .usersContainer .content .navMenu .menu .menubtn img
    {
        width: 38px;
        height:38px;
    }
    .usersContainer .content .navMenu .menu .activatedmenubtn img
    {
        width: 38px;
        height:38px;
    }    


}
@media screen and (max-width: 991px) {

    .usersContainer .content .navMenu
    {
        height:230px;
    }    
    .usersContainer .content .tittle
    {
        font-size: 55px;
        line-height: 107px;
        letter-spacing: 0.2em;
        text-align: center;
    }
    .usersContainer .content .navMenu .menu .activatedmenubtn
    {
        margin-top: 0px;
    }
}

@media screen and (max-width: 768px) {

    .usersContainer .content
    {

        padding-left: 30px;
        padding-right: 30px;
    }

}

@media screen and (max-width: 576px) {

    .usersContainer .content
    {

        padding-left: 10px;
        padding-right: 10px;
    }
    .usersContainer .content .tittle
    {
       
        font-size: 30px;
        line-height: 35px;
        letter-spacing: 0.3em;
        text-transform: uppercase;
        color: #A9B7BC;       
    }

}