body{
    font-family: 'Maven Pro', sans-serif;
    background-color: #FFFFFF;
}
.container{
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
button:active {

    outline: none;
    border: none;
}

button:focus {
    outline:0;
}

input:active {

    outline: none;
    border: none;
}

input:focus {
    outline:0;
}

.row {
    margin-left:0;
    margin-right:0;
}

/* .css-1uccc91-singleValue{
    color:white!important
} */



