.partners
{
    
    border-radius: 8px; 
    min-height: 800px;
    height: 100%;
    width: 191px;
    min-width:145px;
    padding-top:25px;
    background: linear-gradient(179.98deg, #233657 -0.21%, #0C78B5 -0.21%, #155F92 22.06%, #1E466D 56.25%);
    
}

.partners .partnerCard
{
    
    border-radius: 6px 0px 0px 6px;
    width: 100%;
    height: 131px;
    margin-bottom: 15px;
    cursor: pointer;
    
}

.partners .partnerCard .cardContainer .partnerimg
{
    width:90px;
    height: 85px;
    background-color: #f0f0f0;
    border: 0;
    box-shadow: 0px 3.89114px 3.89114px rgba(0, 0, 0, 0.25);
    border-radius: 1.94557px;
    margin-bottom: 12px;
    margin-top: 12px;    
}

.partners .partnerCard .cardContainer .partnerName
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    
    color: #FFFFFF;
    padding-left:5px;
    padding-right: 5px;
    overflow: hidden;
}

.whiteBackground{
    background-color: white;
}