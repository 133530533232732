.askButton
{


    font-family: Maven Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
    width: 394px;
    height: 55px;
   
    background: #2686B0;
    border-radius: 6px;
    margin-top: 70px;    
}

.askButton:hover
{
    opacity: 0.8;
}