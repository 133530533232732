.serviceTittle{

    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #2686B0;
    padding-top: 50px;
    margin-bottom: 25px;

}

.servicesBlog
{
    width: 100%;
   /*  height: 410px; */
  /*   min-width: 400px; */
    min-height: 410px;
    max-width: 569px;
  /*   max-height: 410px; */
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 20px;
}

.ServiceImage
{
    width: 100%;
    height: 271px;

    min-height: 271px;
    max-width: 569px;
    max-height: 271px;
    border-radius: 2px;
    border :0;
    background-color: #C4C4C4;
    background-size:cover;
    background-repeat: no-repeat;
    background-position: center;

}
.ServiceDescription
{
    width: 100%;
    max-width: 569px;

 /*    height: 79px;
    max-height: 79px;
    min-height: 79px; */

    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 0.1em;
/*     text-transform: capitalize; */
    color: #3B3B3A;


    overflow: hidden;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 10px;
}
.ServiceLink
{
    text-align: right;
    padding-right:2px;
    cursor: pointer;

    font-family: Maven Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 15px;
    line-height: 26px;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #2686B0;

}
.ServiceTitle
{
    text-align: left;
    padding-right:2px;


    font-family: Maven Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    margin-top: 10px;
    line-height: 26px;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #2686B0;

}
