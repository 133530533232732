.innerContainer
{
    padding-left:10px;
    padding-right:4px;
    padding-top:98px;
}

.innerContainer .innerAligner
{
    width: 100%;
}
.adsdetail
{
  padding-left:77px;
  padding-right:60px;
}

.adsdetail .adsopentext
{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    /* identical to box height, or 182% */
    
    letter-spacing: 0.1em;
    text-transform: capitalize;
    
    color: #4F4F4F;
    margin-bottom: 6px;
        
}

.adsdetail .mainimg
{
    height: 356px;
    max-height: 356px;
}
.adsdetail .mainimg img
{
    max-height: 356px;
    height: 356px;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.adsdetail .jobsname
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 80px;
    /* or 160% */

    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;

    color: #4F4F4F;
    margin-top: 18px;
    margin-bottom: 9px;
}

.adsdetail .hdecorline
{
    border: 0.5px solid #4F4F4F;
}

.adsdetail .jobinfo
{
    min-height: 95px;
    height:95px;
    margin-top:38px;
    margin-bottom:50px;
}

.adsdetail .leftside
{
    border-right: 1px solid #000000;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;    
}
.adsdetail .leftside .maintittle
{
    font-family: Monda;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 49px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #1E466D;
}
.adsdetail .leftside .subtittle
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 244.34%;
    /* or 29px */
    
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    
    color: #4F4F4F;
}

.adsdetail .rightside
{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.adsdetail .rightside .maintittle
{
    font-family: Monda;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 49px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #1E466D;
}
.adsdetail .rightside .subtittle
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 244.34%;
    /* or 29px */
    
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    
    color: #4F4F4F;
}
.adsdetail .imggalery
{
    margin-bottom: 92px;
}
.adsdetail .imgformater
{
    margin-left:18px;
    margin-right:18px;
    margin-bottom: 10px;
    width: 293px;
    height: 213px;
    object-fit: cover;
    object-position: center;
}
.adsdetail .subtittleads
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 49px;
    /* or 49px */
    
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    
    color: #4F4F4F;  
    margin-bottom: 30px;  
}

.adsdetail .mapsformat
{
    margin-top: 21px;
    margin-bottom: 83px;
}

.adsdetail .jobdescription
{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: capitalize;

    color: #4F4F4F;
    margin-bottom:100px;
}


@media screen and (max-width: 1045px) {
.innerContainer .innerAligner
    {
    width: 100% !important;
    }
}


@media screen and (max-width: 992px) {
.innerContainer .innerAligner
    {
    width: 100% !important;
    }
}


@media screen and (max-width: 880px) {
    .adsdetail .jobsname {
        font-size: 30px;
    }
    .adsdetail .jobinfo{
        display: block !important;
        min-height: unset;
        height: unset;
    }


    .adsdetail .leftside
    {
        width: 100% !important;
        border-right: none;
        margin-bottom: 40px;
    }
    .adsdetail .rightside
    {
        width: 100% !important;
    }    
}

@media screen and (max-width: 880px) {
    .adsdetail
    {
      padding-left:10px;
      padding-right:10px;
    }    
}