.innerContainer
{
    padding-left:10px;
    padding-right:4px;
    padding-top:98px;
}


.innerContainer .innerAligner
{
    width: 100%;
}
.agreementdetail
{
  padding-left:77px;
  padding-right:60px;
}

.agreementdetail .agreementadstittle
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 49px;

    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;

    color: #4F4F4F;
}
.agreementdetail .tittlemodder
{


    font-size: 15px;
    line-height: 53px;

}


.agreementdetail .agreementadssection
{
    background: #FFFFFF;
    box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    margin-bottom: 46px;
}

.agreementdetail .agreementadssection .vdecor
{
    border: 1px solid #4F4F4F;
    margin-top: 50px;
    margin-bottom: 39px;
}
.agreementdetail .agreementadssection .agreementads-left
{
    max-width: 350px;
    width: 100%;
    padding-left: 49px;
    padding-top: 32px;
    padding-right: 25px;
    padding-bottom: 32px;
    
}
.agreementdetail .agreementadssection .agreementads-left .adsleft-subtittle
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: left;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #4F4F4F;
}
.agreementdetail .agreementadssection .agreementads-left .offpaddingsmall
{
    margin-bottom: 18px;
}
.agreementdetail .agreementadssection .agreementads-left .adsleft-text
{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 0.1em;
    text-transform: capitalize;
    text-align: justify;
    color: #4F4F4F;
}


.agreementdetail .agreementadssection .agreementads-middle
{
    max-width: 350px;
    width: 100%;
    padding-left: 25px;
    padding-top: 43px;
    padding-right: 0px;
    padding-bottom: 0px;  
}

.agreementdetail .agreementadssection .agreementads-middle .adsright-subtittle
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #1E466D;
}

.agreementdetail .agreementadssection .agreementads-middle .adsright-text
{
    font-family: Maven Pro;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 29px;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    color: #4F4F4F;    
}


.agreementdetail .agreementadssection .agreementads-middle .offpaddingsmall
{
    margin-bottom: 18px;
}

.agreementdetail .agreementadssection .agreementads-right .agreementads-img
{
    width: 347px;
    height: 293px;
    object-fit: cover;
    object-position: center;
}

.agreementdetail .agreementads-hdekor
{
    border-top: 0.5px solid #4F4F4F;
    margin-top:22px;
    margin-bottom:22px;
    min-height: 1px;
}
.agreementdetail .tittlemargin
{
    margin-top: 50px;
    margin-bottom: 30px;
    
}
.agreementdetail .tittlemarginb
{
    margin-top: 20px;
    margin-bottom: 80px;
    
}


.agreementdetail .agreementads-endbuttons .declinebtn
{


    width: 339px;
    height: 65px;
    background: #C4C4C4;
    border-radius: 6px;
    border: 0;
 
    font-family: Maven Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    
    color: #FFFFFF;
    margin-right:24px;

}
.agreementdetail .agreementads-endbuttons .declinebtn:hover
{
    opacity: 0.8;    
}

.agreementdetail .agreementads-endbuttons .acceptbtn
{

    width: 406px;
    height: 65px;

    
    background: #217BA7;
    border-radius: 6px;
    border: 0;

    
    font-family: Maven Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 23px;

    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    
    color: #FFFFFF;
    
}
.agreementdetail .agreementads-endbuttons .acceptbtn:hover
{
    opacity: 0.8;    
}



.agreementdetail .demotivator
{
    padding-left:80px;
    padding-right:90px;
    margin-bottom: 100px;
}
.agreementdetail .agreement-content
{
    font-family: Open Sans;
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 20px;
letter-spacing: 0.1em;

color: #4F4F4F;
margin-bottom:100px;
}



@media screen and (max-width: 1440px) {

    .innerContainer
    {
        width:100%
    }
    
    .innerContainer .innerAligner
    {
        width:  100%;
    } 

    .agreementdetail .agreementadssection .agreementads-left
    {
        order:2;
        max-width: 57%;
        width:100%;
    }    
    .agreementdetail .agreementadssection .agreementads-right
    {
        order:1;
    }
    .agreementdetail .agreementadssection .agreementads-middle
    {
        order:4;
        max-width: 100%;
        text-align: center;
        padding-left: 0px;
        margin-bottom: 30px;
        padding-top: 0px; 

    }    
    .agreementdetail .agreementadssection .vdecor
    {
        order:3;
        border-right :none;
        border-left:none;
        width: 100%;
        border-bottom: solid 1px;
        margin-left:30px;
        margin-right:30px;
    }   
    
    
    .agreementdetail .agreementads-endbuttons .acceptbtn
    {
        width: 375px;
    }

    .agreementdetail .agreementads-endbuttons .declinebtn
    {
        width: 240px;
    }

}


@media screen and (max-width: 1200px) {


    .agreementdetail .agreementadssection .agreementads-left
    {
        order:2;
        max-width: 100%;
        width:100%;
    }    
    .agreementdetail .agreementadssection .agreementads-right
    {
        order:1;
        width:100%;
    }
    .agreementdetail .agreementadssection .agreementads-right .agreementads-img {
        width: 100%;
    }
    .agreementdetail .agreementadssection .agreementads-middle
    {
        order:4;
        max-width: 100%;
        text-align: center;
        margin-bottom: 30px;
        padding-top:30px;
        

    }    
    .agreementdetail .agreementadssection .vdecor
    {
        order:3;
   
        margin-top: 15px;
        margin-bottom: 0px;
    }     
    
    
    .agreementdetail .agreementads-offerssection .agreementads-elements .offeradselementcontainer {
        max-width: 400px;
        min-width: 400px;
        }

        .agreementdetail .agreementads-endbuttons .acceptbtn
        {
            width: 210px;
            font-size: 16px;
        }
    
        .agreementdetail .agreementads-endbuttons .declinebtn
        {
            width: 199px;
            font-size: 16px;
        }


}

@media screen and (max-width: 992px) {

    .agreementdetail .demotivator {
        padding-left: 0px;
        padding-right:0px;
    }
    .agreementdetail .agreementads-offerssection .agreementads-elements .offeradselementcontainer {
    max-width: 400px;
    min-width: 400px;
    }


    .agreementdetail .agreementads-endbuttons .acceptbtn
    {
        width: 200px;
        font-size: 16px;
    }

    .agreementdetail .agreementads-endbuttons .declinebtn
    {
        width: 154px;
        font-size: 16px;
               
    }


}
@media screen and (max-width: 576px) {

        .agreementdetail {
            padding-left: 10px;
            padding-right: 10px;
        }
        
        .agreementdetail .agreementads-offerssection .agreementads-elements .offeradselementcontainer {
            max-width: 300px;
            min-width: 300px;
            }   
            
            .agreementdetail .agreementads-endbuttons .acceptbtn
            {
                width: 100%;
                font-size: 16px;
            }
        
            .agreementdetail .agreementads-endbuttons .declinebtn
            {
                width: 100%;
                font-size: 16px;
                margin-right: 0px;
                margin-bottom: 30px;                
            }

}