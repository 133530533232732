.askOffer
{
    background: #1A4E79;
    z-index: -10;
    
    width:100%;
    margin-top: -113px;
    padding-top: 245px;
    padding-left: 125px;
    padding-right: 112px;
    margin-bottom: 80px;
}

.askOffer .leftPadding
{
    padding-right:57px;
}
.askOffer .centerPadding
{
    padding-left:35px;
    padding-right:35px;
}
.askOffer .rightPadding
{
    padding-left:57px;
}


.askOffer .askTittle
{
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 27px;
    text-align: justify;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: #FFFFFF;



    
}

.askOffer .askDescription
{
    font-family: "Open Sans";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    text-align: justify;
    letter-spacing: 0.15em;
    color: #FFFFFF;
    margin-top: 30px;
    padding-bottom: 93px;
    list-style-type: none;
}

.askOffer button {

    background: #2686B0;
    border-radius: 6px;
    
    
    /* Tovább A kérdésekre */
    
    
   
    width: 394px;
    height: 55px;
    
    font-family: Maven Pro;
    font-style: normal;
    font-weight: 900;
    font-size: 15px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.3em;
    text-transform: uppercase;
    
    color: #ECECED;    
    margin-bottom: 80px;
    border: 0;

}

@media screen and (max-width: 576px) {

    .askOffer{
        padding-left: 15%;
        padding-right: 15%;
    }
}


@media screen and (max-width: 992px) {
    
    .askOffer .leftPadding{
        padding-right: 0px;
        padding-left: 0px;
    }

    .askOffer .centerPadding{
        padding-right: 0px;
        padding-left: 0px;
    }  
    .askOffer .rightPadding{
        padding-right: 0px;
        padding-left: 0px;
    }        

}